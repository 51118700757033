<template>
    
    <div id="page-gerenciar">
        <b-list-group>

            <h2>Gerenciar Solicitações de Escala</h2>

            <div class="row" style="justify-content: space-between;padding: 0px 25px;">
                
                <!-- <div class="filter-field">
                    <span class="filter-title">Filtrar por período:</span>
                    <date-picker
                        id="input-periodo"
                        v-model="dataFiltro"
                        lang="pt-br" 
                        range
                        style="width:220px;"
                        type="date"
                        format="DD/MM/YYYY"
                        @change="getEscalas"> 
                    </date-picker>
                </div> -->

                <div class="row">
                    <b-form-group label="Filtrar por período" label-for="input-period" class="filter-field">
                        <date-picker
                            id="input-period"
                            v-model="dataFiltro"
                            lang="pt-br" 
                            range
                            type="date"
                            format="DD/MM/YYYY"
                            @change="getEscalas"> 
                        </date-picker>
                    </b-form-group>

                    <b-form-group label="Filtrar por solicitante" label-for="input-requester" class="filter-field">
                        <!-- <b-form-select id="input-requester" v-model="currentClient" :options="clientList">
                        </b-form-select> -->
                        <b-form-select id="input-requester" v-model="currentClient">
                            <b-form-select-option :value="null"></b-form-select-option>
                            <b-form-select-option v-for="(item, index) in clientList" :key="index" :value="item">{{item.nome}}</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group label="Filtrar por setor" label-for="input-sector" class="filter-field">
                        <!-- <b-form-select id="input-sector" v-model="currentUnit" :options="unitList">
                        </b-form-select> -->
                        <b-form-select id="input-sector" v-model="currentUnit">
                            <b-form-select-option :value="null"></b-form-select-option>
                            <b-form-select-option v-for="(item, index) in unitList" :key="index" :value="item">
                                    <div style="font-weight: bold !important">{{item.nome_setor}}</div>
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </div>

                <div style="padding-top: 30px;">
                    <b-button class="" variant="btn btn-info btn-sg" @click="getEscalas"> Atualizar escalas</b-button>

                </div>
                
            </div>


            

            <div class="tabela">
                <b-table 
                    id="my-table"
                    striped 
                    hover 
                    class="text-center" 
                    ref="selectableTable" 
                    :items="items" 
                    :fields="fields" 
                    :busy="load"
                    small
                    outlined
                    >


                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong></strong>
                        </div>
                    </template>


                    <template #cell(Editar)="data">
                        <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->
                        <img src="../../assets/edit1.png" alt="editar" height="23px" @click="EditEscala(data.item)">                       
                    </template>    
                    <template #cell(Apagar)="data">
                        <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->
                        <img src="../../assets/del4.png" alt="editar" height="23px" @click="showModal(data.item)">                       
                    </template>    
                </b-table>

            </div>

            <span>*As escalas ficam disponíveis para alteração até {{tempoLimiteEdit}} antes do início do evento.</span>

        </b-list-group>

        <div>
            <b-modal ref="modal-del" hide-footer title="">
                <div class="d-block text-center">
                    <h3>Você confirma a exclusão?</h3>
                </div>
                <b-button class="mt-3" variant="outline-success" block @click="showModalDelMsg">Sim</b-button>
                <b-button class="mt-2" variant="outline-danger" block @click="hideModal">Não</b-button>
            </b-modal>
        </div>

        <div>
            <b-modal ref="modal-del-msg" hide-footer title="">
                <div class="d-block text-center">
                    <h3>Notificar gestores e profissionais envolvidos?</h3>
                </div>
                <b-button class="mt-3" variant="outline-success" block @click="delEvento('sim')">Sim</b-button>
                <b-button class="mt-2" variant="outline-danger" block @click="delEvento('nao')">Não</b-button>
            </b-modal>
        </div>

    </div>

</template>

<script>
import api from '../../services/api'
import dayjs from 'dayjs'
import { msg } from '@/global'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';
import { BFormGroup, BFormSelect, BFormSelectOption } from 'bootstrap-vue';
import eventService from '../../services/eventService';
// import AppConstants from '@/appConstants'

export default {
    name: 'GerenciarEscalas',
    components: { 
        DatePicker,
        BFormGroup, 
        BFormSelect,
        BFormSelectOption
    },
    data(){
        return{

            dadosHeader: {},
            escalas: [],
            tempoLimiteEdit: '0 hora E 0 minuto',
            dataFiltro: [],

            fields: [
                {key: 'solicitante', label: 'solicitante', formatter: 'solicitante'},
                {key: 'dataEvento', label: 'data Início', formatter: value => {
                    const valores = value.split('-')
                    return valores[2]+'/'+valores[1]+'/'+valores[0]
                }},
                {key: 'nomeEvento', label: 'Nome do Evento', formatter: 'nomeEvento'},
                {key: 'setor.text', label: 'Setor', formatter: 'setor'},
                {key: 'status', label: 'Status', formatter: 'status'},
                {key: 'orcamento', label: 'Orçamento', formatter: value => {
                    const valor = parseFloat(value)
                    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                }},
                'Editar',
                'Apagar'
            ],

            items: [],
            idEvento: null,
            statusEvento: null,

            load: true,
            perPage: 10,
            currentPage: 1,
            clientList: [],
            currentClient: null,
            unitList: [],
            currentUnit: null,
            isInitValClientId: false,
        }
    },

    created() {
        this.isInitValClientId = true;
        this.dataFiltro[0] = new Date(dayjs().subtract(7, 'day').format('YYYY-MM-DD'))
        this.dataFiltro[1] = new Date(dayjs().add(30, 'day').format('YYYY-MM-DD'))

        // this.clientList = [{value: null, text: ''},...this.clientesUnidades.map((item) => {
        //     return { value: item.id, text: item.nome_comercial };
        // })];

        // const ids = this.getIdClienteUnidade;
        // const cli = this.clientesUnidades.find(item => item.id == ids.idCliente);
        // if (cli) {
        //     this.currentClient = cli.id;
        //     if (this.currentClient && ids) {
        //         const unit = cli.unidades.find(item => item.id == ids.idUnidade);
        //         if (unit) {
        //         this.currentUnit = unit.id;
        //         }
        //     }
        // }
        // this.getEscalas();
    },
    mounted() {
        // this.getEscalas()
        this.loadInitialData();
        this.getTempoLimitEdit();
    },
    watch: {
        getIdClienteUnidade() {
            this.loadInitialData();
        },
        // currentClient(val) {
        // if (!this.isInitValClientId) {
        //     this.currentUnit = null;
        // }
        // this.isInitValClientId = false;
        // this.unitList = [];
        // if (val) {
        //     const selectedClient = this.clientesUnidades.find(item => item.id == val);
        //     if (selectedClient && selectedClient.unidades) {
        //     this.unitList = [{value: null, text: ''},...selectedClient.unidades.map(item => {
        //         return {value: item.id, text: item.nome_unidade}
        //     })];
        //     }
        // }
        // }
    },
    methods: {
        getTempoLimitEdit() {
            api.get('webcliente/eventos/tempo-limite-edit')
            .then(res => {
                this.tempoLimiteEdit = res.data
            })
            .catch(err => msg(err.response.data, 'vermelho', 3000))
        },


        showModal(data) {
            // console.log('kkkkkkkkkkkkkkkkkkkkkk',data)
            this.idEvento = data.idEvento
            this.statusEvento = data.status
            this.$refs['modal-del'].show()
        },
        hideModal() {
            this.$refs['modal-del'].hide()
        },

        showModalDelMsg() {
            this.$refs['modal-del'].hide()

            // this.statusEvento == 'Em edição' ? this.delEvento('nao') : this.$refs['modal-del-msg'].show()

            if (this.statusEvento == 'Em edição') {
                this.delEvento('nao')
            } else {
                this.$refs['modal-del-msg'].show()
            }
        },

        delEvento(acao) {
            this.$refs['modal-del-msg'].hide()

            api.delete(`webcliente/eventos/${this.idEvento}/${acao}`)
            .then(() => {

                msg('EVENTO REMOVIDO COM SUCESSO!!', 'verde', 3000)
                this.getEscalas()
            })
            .catch(err => msg(err.response.data, 'vermelho', 3000))
        },

        loadInitialData() {
            // const ids = this.getIdClienteUnidade;
            this.clientList = [];
            this.unitList = [];
            this.currentClient = null;
            this.currentUnit = null;
            const params = new URLSearchParams();
            params.append('id_unidade', this.getIdClienteUnidade.idUnidade);
            params.append('id_cliente', this.getIdClienteUnidade.idCliente);

            Promise.all([
                eventService.getClients(params),
                eventService.getSectors(params)
            ]).then(res => {
                this.clientList = res[0];
                this.unitList = res[1];
                this.getEscalas();
            }).catch(err => console.log(err));
        },

        getEscalas() {

            this.load = true

            // const ids = this.getIdClienteUnidade
            // console.log('ids ',ids)
            const dataIni = dayjs(this.dataFiltro[0]).format('YYYY-MM-DD')
            const dataFim = dayjs(this.dataFiltro[1]).format('YYYY-MM-DD')

            const params = new URLSearchParams();
            params.append('id_unidade', this.getIdClienteUnidade.idUnidade);
            // params.append('scaleClosed', true);
            // params.append('status', AppConstants.EVENT_STATUS.APPROVED);

            if (this.currentClient && this.currentClient.id) {
                params.append('id_clientes_usuarios', this.currentClient.id);
            } else {
                params.append('id_cliente', this.getIdClienteUnidade.idCliente);
            }
            if (this.currentUnit && this.currentUnit.id) {
                params.append('id_setor', this.currentUnit.id);
            }

            if (dataIni) {
                params.append('data_ini', dataIni);
            }
            if (dataFim) {
                params.append('data_fim', dataFim);
            }

            // api.get(`webcliente/eventos?id_cliente=${ids.idCliente}&id_unidade=${ids.idUnidade}&data_ini=${dataIni}&data_fim=${dataFim}`)
            eventService.getEvents(params).then(res => {
                this.load = false
                this.escalas = res.data ? res.data : res;
                // console.log('weeeeeeeeeeeeeeeeeeeeee',res.data)

                const escalasMapped = this.escalas.map(item => {
                    const escala = {
                        isActive: true, 
                        idEvento: item.id_evento,
                        solicitante: item.nome_solicitante, 
                        dataEvento: item.data_evento, 
                        dataView: item.data_evento_view, 
                        nomeEvento: item.nome_evento, 
                        entrada: item.entrada,
                        // setor: item.nome_setor, 
                        setor: {
                            value: item.id_setor,
                            text: item.nome_setor,
                            funcoes: item.funcoes
                        }, 
                        status: item.status,
                        obs: item.obs,
                        endereco: item.endereco,
                        orcamento: item.orcamento
                    }
                    return escala
                })

                this.items = escalasMapped;

            })
            .catch(err => {
                this.load = false
                msg(err.response.data, 'vermelho', 5000)
            })

        },

        EditEscala(escala) {
            // console.log(escala)
            const idEvento = escala.idEvento
            this.$store.commit('escala/setIdEvento', idEvento)

            const eventoHeaderEtapa2 = {
                solicitante: escala.solicitante,
                nome: escala.nomeEvento,
                dataView: escala.dataView,
                data: escala.dataEvento,
                setor: escala.setor,
                endereco: escala.endereco,
                comentarios: escala.obs
            }
            localStorage.setItem('eventoHeaderEtapa2', JSON.stringify(eventoHeaderEtapa2))        
            this.$router.push({ name: 'criaEscala2' })   
            
        }
    },

    computed: {
        getIdClienteUnidade() {
            return this.$store.state.escala.idClienteUnidade;
        },
        clientesUnidades() {
            return this.$store.state.clientesUnidades;
        },
    }


}
</script>

<style scoped lang="scss">

    /* #id {
        margin: 30px;
    } */

    .btnRight {
        float: right;
        margin-top: 30px;
        margin-right: 15px;
    }

    #page-gerenciar h2 {
        margin-top: 50px;
        margin-bottom: 15px;
        /* color: rgb(70, 81, 82); */
        color: rgb(188, 143, 95);
    }

    .tabela {
        width: 80vw;
    }

    img {
        cursor: pointer;
    }

    span {
        margin-top: 30px;
    }

    table.b-table[aria-busy='true'] {
        opacity: 0.6;
    }


    @media (max-width: 700px) {

        .tabela {
            width: 300px;
            overflow:auto; 
    
        }
    }

    .filter-field {
        min-width: 280px;
        padding: 0px 10px;

        .mx-datepicker-range {
            width: auto;
            .mx-input {
                height: 2.714rem;
            }
        }
    }

</style>