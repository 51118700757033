<template>
  <div id="app">
    <!-- <Header /> -->
    <template v-if="displaySystem">
      <Header v-if="getStatusMenu && initDisplayData" />
      <Loading v-if="getStatusLoading" />
      <div class="content" v-else>
        <router-view v-if="initDisplayData"/>
      </div>
    </template>
  </div>
</template>



<script>
import Header from "./components/Header";
import Loading from "./components/Loading";
import localStorageHelper from "./helpers/local-storage-helper";
import authService from "./services/authService";
import api from './services/api';

export default {
  name: "App",
  // beforeRouteEnter(to, from, next){
  //     if (from.path == '') {

  //     }
  // },
  components: { Header, Loading },
  data() {
    return {
      isMenuVisible: false,
      validatingToken: true,
      initDisplayData: false,
      displaySystem: false,
    };
  },
  async beforeCreate() {
    const configResp = await fetch("config.json");
    const conf = await configResp.json();
    api.defaults.baseURL = conf.api_url;
    this.displaySystem = true;
  },
  async created() {
    // console.log("iniciouuu");
    // this.$router.push("/login");
    const userData = localStorageHelper.getCurrentUser();
    if (userData) {
        this.$store.commit("auth/setUser", userData);
    }
    this.validateToken();
    await this.initStoreData();

    // Deixa acessar pagina sem autenticar se for o caminho abaixo, se não ele direciona para o login.
    try {
      // console.log("iniciouuu",this.$router);
      // console.log("path", path);

      // TODO - UNCOMENT
      const path = this.$router.history._startLocation.split("?")[0];
      if (path == "/nova-senha") {
        this.$router.push(this.$router.history._startLocation);
      }
      // else {
      //     this.$router.push("/login")
      // }
    } catch (error) {
      this.$router.push("/login");
    }
  },

  methods: {
    async initStoreData() {
      if (!this.currentUser) {
        this.initDisplayData = true;
        return;
      }
      try {
        await authService.updateClienteUnidadeLocalData();
        // console.log(resp);
      } catch (error) {
        console.error(error);
      }
        // const clientUnit = localStorageHelper.getClientesUnidades();
        // if (clientUnit) {
        //   this.$store.commit("setClientesUnidades", clientUnit);
        // }
        const clientId = localStorageHelper.getSelectedClientId();
        if (clientId) {
          this.$store.commit('setClientId', clientId);
        }
        this.initDisplayData = true;
    },
    async validateToken() {
      // console.log("valitation");
      this.validatingToken = true;
      if (!this.currentUser) {
        this.validatingToken = false;
        this.$store.commit("auth/setUser", null);
        this.$router.push({ name: "auth" });
        return;
      }
      
      const res = await authService.validateToken(new URLSearchParams(), {
        token: this.currentUser.token,
      });
      if (res.data) {
        // this.$store.commit("auth/setUser", userData);
        this.$router.push({ name: "home" });
      }
      // } else {
      //     localStorageHelper.deleteCurrentUser();
      //     this.$router.push({ name: 'auth' })
      // }

      this.validatingToken = false;
    },
  },

  computed: {
    currentUser() {
        return this.$store.state.auth.user;
    },
    getStatusMenu() {
      //console.log(this.$store.state.isAuth);
      return this.$store.state.auth.isMenuVisible;
      // return this.$store.state.isMenuVisible;
    },
    getStatusLoading() {
      return this.$store.state.auth.loading;
    },
  },
};
</script>





<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
</style>
