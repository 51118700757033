<template>

    <div id="cria-etapa2">

        <b-button v-if="this.statusEvento" class="voltar" variant="warning" @click="toGerenciarEventos">Voltar</b-button>

        <!-- <b-card-group columns> -->
        <b-list-group>

            


            <b-card class="card-evento" bg-variant="light" text-variant="black" title="">               
                
                <div>

                    <b-row>
                        <b-col sm="6">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Solicitante:" label-for="input-solcitante">
                                <b-form-input id="input-solcitante"  class="text-uppercase" readonly :value="dadosHeader.solicitante"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Nome do Evento:" label-for="input-nome-evento">
                                <b-form-input id="input-nome-evento"  class="text-uppercase" readonly :value="dadosHeader.nome"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="3">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Setor:" label-for="input-setor">
                                <b-form-input id="input-setor" class="text-uppercase" readonly :value="dadosHeader.setor.text"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="6">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Data:" label-for="input-data">
                                <b-form-input id="input-data"  class="text-uppercase" readonly :value="dadosHeader.dataView"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6">
                            <b-form-group label-cols="12" label-cols-lg="12" label="Endereço:" label-for="input-endereco">
                                <b-form-input id="input-endereco"  class="text-uppercase" readonly :value="dadosHeader.endereco"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>

                
                    
                    <!-- <b-button href="#" variant="" class="botao botao-editar" @click="toEditarEtapa1()">Editar evento</b-button> -->
                <!-- <router-link to="/criar-escala-1" tag="button" class="botao botao-editar" exact>Editar evento</router-link> -->

                </div>

            </b-card>



            

            <!-- <div class="mt-3">Selected: <strong>{{ timeEntrada }}</strong></div> -->
            <!-- <div class="mt-3">Setor: <strong>{{ this.$store.state.escala.idEvento }}</strong></div> -->



            <b-card class="card-escala" bg-variant="light" text-variant="black" title="Adicionar Escala">                
                <b-overlay
                    :show="displayLoadingHour"
                    spinner-variant="primary"
                    blur="0"
                    opacity=".75"
                    rounded="sm"
                >
                    <div>

                        <b-row>
                            <b-col sm="3">
                                <b-form-group label-cols="12" label-cols-lg="12" label="Função" label-for="input-funcao">
                                    <b-form-select v-model="selectedFuncao" @change="getFuncaoHoraFinal" :options="funcoes"></b-form-select>
                                    <!-- <div class="mt-3">Selected: <strong>{{ selectedFuncao }}</strong></div> -->
                                </b-form-group> 
                            </b-col>
                            <b-col sm="3">
                                <b-form-group label-cols="12" label-cols-lg="12" label="Quantidade" label-for="input-quantidade">
                                    <b-form-input id="input-quantidade" type="number" min="1" v-model="quantidade" class="text-uppercase"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <b-form-group label-cols="12" label-cols-lg="12" label="Entrada" label-for="input-entrada">
                                    <vue-timepicker v-model="timeEntrada" @change="getFuncaoHoraFinal" manual-input hide-clear-button auto-scroll close-on-complete drop-direction="up"></vue-timepicker>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <b-form-group label-cols="12" label-cols-lg="12" label="Saída" label-for="input-entrada">
                                    <vue-timepicker v-model="timeSaida" manual-input hide-clear-button :hour-range="endHourRange" auto-scroll close-on-complete drop-direction="up" :disabled="isEditingData"></vue-timepicker>
                                </b-form-group>
                                <!-- hide-disabled-hours -->
                            </b-col>

                        </b-row>

                    </div>
                </b-overlay>


            

                <b-button href="#" variant="info" class="botao" @click="addEscala()">{{botaoAddText}}</b-button>
                <b-button href="#" variant="warning" class="botao botao-editar" @click="cancelarEditar()" v-show="botaoCancelar">Cancelar</b-button>
                <!-- <router-link to="/criar-escala-2" tag="button" class="botao" exact style="background: yellowgreen;">Adiciona escala</router-link> -->
             
            </b-card>                    


            <div class="div-escalas" v-if="items.length == 0">
                <br> <br> <br> <br> <br> <br> 
            </div>
            <div v-else>

                <div class="tabela">
                    <b-table striped hover :items="items" class="text-center" :fields="fields" ref="selectableTable"
                    selectable
                    :select-mode="single"
                    @row-selected="onRowSelected">

                        <template #cell(Detalhes)="data">
                            <b-icon class="icones" icon="info-circle-fill" scale="1.3" variant="info" @click="showModalValores(data)"></b-icon> 
                            <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="showModalValores(data)"> -->
                            <!-- <img src="../../assets/del4.png" alt="editar" height="23px" @click="showModal(data)">                        -->
                        </template>  

                        <template #cell(Editar)="data">
                            <img src="../../assets/edit1.png" alt="editar" height="23px" @click="editarEscala(data.item)" v-if="permissaoEditar">                       
                            <img src="../../assets/edit.png" alt="editar" height="23px" @click="showModalEditQtd(data.item)" v-else>                       
                        </template>    
                          
                        <template #cell(Apagar)="data">
                            <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="showModal(data)"> -->
                            <img src="../../assets/del4.png" alt="apagar" height="23px" @click="showModalApagar(data.item)">                       
                        </template>    
                        
                    </b-table>
                </div>


                <div>
                    <b-modal id="modal-valores" ref="modal-valores" title="Detalhamento do valor" ok-only>
                        <div class="tabela">
                            <b-table striped :items="itemsValores" class="text-center" :fields="fieldsValores" ref="selectableTable"> 
                                
                            </b-table>
                        </div>
                    </b-modal>
                </div>
                

                <div>
                    <b-modal id="modal-apagar" ref="modal-apagar" hide-footer title="">
                        <div class="d-block text-center">
                            <h3>Você confirma a exclusão?</h3>
                        </div>
                        <b-button class="mt-3" variant="outline-success" block @click="delEscala">Sim</b-button>
                        <b-button class="mt-2" variant="outline-danger" block @click="hideModalApagar">Não</b-button>
                    </b-modal>
                </div>

                <div>
                    <b-modal id="modal-edit-qtd" ref="modal-edit-qtd" hide-footer title="Alterar a quantidade da escala">
                        <div class="d-block text-center">
                            <!-- <h3>Você deseja alterar a quantidade?</h3> -->
                        </div>
                        <div>
                            <b-row>
                                <b-col sm="6">
                                    <b-form-group label-cols="12" label-cols-lg="12" label="Quantidade" label-for="input-qtd-atual">
                                        <b-form-input  class="text-uppercase" readonly :value="qtd"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="6">
                                    <b-form-group label-cols="12" label-cols-lg="12" label="Quantidade atualizada" label-for="input-qtd-atualaliza">
                                        <b-form-input  type="number" min="1" class="text-uppercase" v-model="qtdAtualizada"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                        <b-button class="mt-3" variant="outline-success" block @click="editarEscalaQtd">Confirmar</b-button>
                        <b-button class="mt-2" variant="outline-danger" block @click="hideModalEditarQtd">Cancelar</b-button>
                    </b-modal>
                </div>
  

        
            </div>





            <b-card class="card-etapa" bg-variant="light" text-variant="black">
                
                <b-card-group deck class="div-circulo">

                    <div class="circulo circulo-ok">
                        <strong class="strong-ok">1</strong>
                        <div class="barra barra-ok"></div>
                        <div class="etapa-texto etapa-texto-ok">
                            Dados iniciais
                        </div>
                        
                    </div>
                    <div class="circulo circulo-ok">
                        <strong class="strong-ok">2</strong>
                        <div class="barra"></div>
                        <div class="etapa-texto etapa-texto-ok">
                            Criação de escala
                        </div>
                    </div>
                    <div class="circulo">
                        <strong>3</strong>
                        <div class="barra barra-falsa"></div>
                        <div class="etapa-texto">
                            Conclusão
                        </div>
                    </div>                

                </b-card-group>

            </b-card>

            <div class="div-proxima-etapa">
                <span id="disabled-wrapper" class="d-inline-block span-disabled-btn" tabindex="0">
                    <b-button href="#" variant="info" class="botao-proxima-etapa" id="btn-next-step" @click="toCriarEtapa3()" :disabled="items.length == 0">Avançar</b-button>
                </span>
                <b-tooltip
                    v-if="items.length == 0"
                    triggers="hover"
                    target="disabled-wrapper"
                    title="Avanço permitido apenas quando selecionar ao menos uma função na montagem da escala"
                />
                <!-- <router-link to="/criar-escala-3" tag="button" class="botao botao-proxima-etapa" exact>Próxima etapa</router-link> -->
            </div>


            
        
        <!-- </b-card-group> -->
        </b-list-group>
    </div>

</template>






<script>
import api from '../../services/api'
import { msg } from '@/global'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import { VBTooltip, BTooltip } from 'bootstrap-vue';
export default {
    directives: {
        "b-tooltip": VBTooltip,
    },
    components: {
        VueTimepicker,
        BTooltip
    },
    data(){
        return{
            dadosHeader: {},
            botaoCancelar: false,
            botaoAddText: 'Adicionar',

            tempoLimiteEdit: '0 HORA E 0 MINUTO',

            idEscala: null,
            qtd: null,
            qtdAtualizada: null,

            selectedFuncao: null,
            funcoes: [],
            timeEntrada : { 
                HH : '12', 
                mm : '00' 
            } ,
            timeSaida : { 
                HH : '12' , 
                mm : '00' 
            } ,
            quantidade: null,
            hora: null,

            fields: [
                {key: 'funcao', label: 'Função', formatter: 'funcao'},
                {key: 'quantidade', label: 'Quantidade', formatter: 'quantidade'},
                {key: 'entrada', label: 'Entrada', formatter: 'entrada'},
                {key: 'saida', label: 'Saída', formatter: 'saida'},
                {key: 'valor', label: 'Valor', formatter: value => {
                    const valor = parseFloat(value)
                    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                }},
                'Detalhes',
                'Editar',
                'Apagar'
            ],
            items: [],

            fieldsValores: [
                {key: 'descricao', label: '', formatter: 'descricao'},
                {key: 'valor', label: '', formatter: 'valor'}
            ],
            itemsValores: [
                {descricao: 'Horas Normais', valor: 23.25},
                {descricao: 'Horas Normais', valor: 23.25},
            ],
            statusEvento: null,
            isEditingData: false,
            displayLoadingHour: false,
            endHourRange: [[1,24]]
        }
    },

    created() {
        const now = new Date()
        this.value = new Date(now.getFullYear(), now.getMonth(), now.getDate())

        const local = localStorage.getItem('eventoHeaderEtapa2')
        this.dadosHeader = JSON.parse(local)
        // console.log('create',this.dadosHeader)

        this.funcoes = this.dadosHeader.setor.funcoes.map(item => {
            const funcao = {
                value: {
                    idFuncao: item.id_funcao,
                    idSetor: this.dadosHeader.setor.value
                },
                text: item.alias_funcao,
            }

            return funcao
        }).slice().sort((x, y) => {
            if (x.text < y.text) {
                return -1;
            }
            if (x.text > y.text) {
                return 1;
            }
            return 0;
        });
        
        const idEvento = this.$store.state.escala.idEvento 
        // console.log('create',idEvento)
        this.getEscalas(idEvento)
        this.getStatusEvento(idEvento)

    },

    methods: {
        onRowSelected() {
            // console.log(el[0].id)
        },

        getFuncaoHoraFinal() {
            if (!this.selectedFuncao) return;
            // console.log('[getFuncaoHoraFinal]');
            this.displayLoadingHour = true;
            const idSetor = this.selectedFuncao.idSetor
            const idFuncao = this.selectedFuncao.idFuncao
            const dataEvento = this.dadosHeader.data
            const horaEntrada = this.timeEntrada.HH+':'+this.timeEntrada.mm
            this.endHourRange = [];

            api.get(`webcliente/escalas/funcao-hora-final/${idSetor}/${idFuncao}/${dataEvento}/${horaEntrada}`)
            .then(res => {
                // console.log(res.data)
                const saida = res.data.split(':')
                this.timeSaida.HH = saida[0]
                this.timeSaida.mm = saida[1]

                this.endHourRange = [[saida[0], 24]];

                this.displayLoadingHour = false;

            })
            .catch(err => {
                msg(err.response.data, 'vermelho', 5000);
                this.displayLoadingHour = false;
            })
        },

        getEscalas(idEvento) {

            // console.log('ssssssssssssssssssss',idEvento)

            api.get(`webcliente/escalas/${idEvento}`)
            .then(res => {

                // console.log('ssssssssssssssssssss',res.data)
                res.data.status ? this.permissaoEditar = false :  this.permissaoEditar = true
                this.tempoLimiteEdit = res.data.tempoLimiteEdit

                this.items = res.data.escalas.map(item => {
                    const escala = {
                        isActive: true, 
                        id: item.id,
                        funcao: item.funcao, 
                        idFuncao: item.id_funcao, 
                        quantidade: item.quantidade, 
                        entrada: item.entrada, 
                        saida: item.saida, 
                        valor: item.valor_cliente,
                        valores: item.valores
                    }
                    return escala
                })

            })
            .catch(err => msg(err.response.data, 'vermelho', 5000))

        },

        showModalValores(data) {
            // console.log(data.item.valores)

            this.itemsValores = data.item.valores
            this.$refs['modal-valores'].show()
        },
        showModalApagar(data) {
            // console.log(data)
            this.idEscala = data.id
            this.$refs['modal-apagar'].show()
        },
        hideModalApagar() {
            this.idEscala = null
            this.$refs['modal-apagar'].hide()
        },

        showModalEditQtd(escala) {
            // console.log('sssssssssssssssss',escala)
            this.idEscala = escala.id
            this.qtd = escala.quantidade
            this.qtdAtualizada = escala.quantidade
            this.$refs['modal-edit-qtd'].show()
        },
        hideModalEditarQtd() {
            this.idEscala = null
            this.$refs['modal-edit-qtd'].hide()
        },

        

        delEscala() {
            this.$refs['modal-apagar'].hide()
            const idEvento = this.$store.state.escala.idEvento 

            // console.log(this.idEscala)
            api.delete('webcliente/escalas/'+this.idEscala)
            .then(() => {

                msg('ESCALA REMOVIDA COM SUCESSO!!', 'verde', 3000)
                this.getEscalas(idEvento)
            })
            .catch(err => msg(err.response.data, 'vermelho', 3000))
        },

        editarEscala(escala) {
            this.isEditingData = true;
            const estrada = escala.entrada.split(':')
            const saida = escala.saida.split(':')

            this.botaoCancelar = true
            this.botaoAddText = 'Atualizar Escala'
            this.idEscala = escala.id
            this.selectedFuncao = {
                idFuncao: escala.idFuncao,
                idSetor: this.dadosHeader.setor.value
            }
            this.quantidade = escala.quantidade
            this.timeEntrada.HH = estrada[0]
            this.timeEntrada.mm = estrada[1]
            this.timeSaida.HH = saida[0]
            this.timeSaida.mm = saida[1]
        },
        cancelarEditar() {
            this.isEditingData = false;
            this.botaoCancelar = false
            this.botaoAddText = 'Adicionar Escala'
            this.idEscala = null
            this.selectedFuncao = null
            this.quantidade = null
            this.timeEntrada.HH = '12'
            this.timeEntrada.mm = '00'
            this.timeSaida.HH = '12'
            this.timeSaida.mm = '00'

        },

        editarEscalaQtd() {
            
            this.$refs['modal-edit-qtd'].hide()
            const idEvento = this.$store.state.escala.idEvento 

            api.put(`webcliente/escalas/qtd/${this.idEscala}/${this.qtd}/${this.qtdAtualizada}`)
            .then(() => {

                msg('QUANTIDADE ATUALIZADA COM SUCESSO!!', 'verde', 3000)
                this.getEscalas(idEvento)
            })
            .catch(err => msg(err.response.data, 'vermelho', 3000))

        },

        addEscala() {   

            if (this.quantidade === null || this.quantidade < 1) {
                msg('Quantidade inválida.', 'vermelho', 3000)
                return
            }

            if (this.timeEntrada.HH == this.timeSaida.HH) {
                msg('Informe a hora corretamente.', 'vermelho', 3000)
                return
            } 

            // if (this.timeSaida.HH < this.timeEntrada.HH) {
            //     msg('A hora de entrada deve ser maior que a hora de saída.', 'vermelho', 5000)
            //     return
            // } 

            const idClienteUnidade = this.getIdClienteUnidade
            const idEvento = this.$store.state.escala.idEvento 
            // console.log('dataEvento', this.dadosHeader.data)

            const escala = {
                id_evento: idEvento,
                id_escala: this.idEscala,
                nome_evento: this.dadosHeader.nome,
                id_cliente: idClienteUnidade.idCliente,
                id_unidade: idClienteUnidade.idUnidade,
                id_setor: this.dadosHeader.setor.value,
                id_funcao: this.selectedFuncao.idFuncao,
                funcao: 'sssss',
                quantidade: this.quantidade,
                entrada: this.timeEntrada.HH+':'+this.timeEntrada.mm,
                saida: this.timeSaida.HH+':'+this.timeSaida.mm,
                data_evento: this.dadosHeader.data
            }


            if (this.idEscala) {
                api.put('webcliente/escalas', escala)
                .then(() => {

                    this.selectedFuncao = null
                    this.quantidade = null
                    msg('ESCALA ATUALIZADA COM SUCESSO!!', 'verde', 5000)

                    this.getEscalas(idEvento)
                })
                .catch(err => msg(err.response.data, 'vermelho', 5000))   

            } else {

                api.post('webcliente/escalas', escala)
                .then(() => {
    
                    this.selectedFuncao = null
                    this.quantidade = null
                    msg('ESCALA ADICIONADA COM SUCESSO!!', 'verde', 5000)
    
                    this.getEscalas(idEvento)
    
    
                })
                .catch(err => msg(err.response.data, 'vermelho', 5000))
            }

            // Evita problemas ara salvar (update, save)
            this.cancelarEditar()

        },


        toCriarEtapa3() {
            localStorage.setItem('path', '/criar-escala-2')
            this.$router.push({ name: 'criaEscala3' })
        },

        toGerenciarEventos() {
            this.$router.push({ name: 'escalas' })
        },

        getStatusEvento(idEvento) {

            api.get(`webcliente/escalas/status-evento/${idEvento}`)
            .then(res => {
    
                this.statusEvento = res.data

            })
            .catch(err => msg(err.response.data, 'vermelho', 5000))

        }

    },

    computed: {
        getIdClienteUnidade() {
            return this.$store.state.escala.idClienteUnidade  
        }
    }

};
</script>










<style scoped src="./CriarEtapa2.css">

</style>