<template>
  <div>
    <b-card title="Relatório de Resumo Financeiro">
      <b-row>
        <b-col md="6">
          <b-card-text><b>Cliente:</b> {{currentClient.nome_comercial}}</b-card-text>
          <b-card-text><b>Unidade:</b> {{currentUnit.nome_unidade}}</b-card-text>
        </b-col>
        <b-col md="6">
            <b>Setor:</b>
            <b-form-group>
              <v-select
                v-model="selectedSector"
                class="form-control-sm"
                label="nome_setor"
                placeholder=""
                :options="sectorList"
                multiple
                :closeOnSelect="false"
              >
                <template #option="{ nome_setor, nome_unidade }">
                  <p>{{nome_setor}} <b v-if="nome_unidade">[{{nome_unidade}}]</b></p>
                </template>
                <span slot="no-options">Nenhum dado encontrado</span>
              </v-select>
            </b-form-group>
        </b-col>
        <b-col md="6">
          <b>Selecione as datas:</b>
          <b-form-group>
            <date-picker 
              v-model="dateRange" 
              range
              type="date"
              format="DD/MM/YYYY"
              :disabled-date="disabledAfterToday"
              @change="loadData"
            ></date-picker>
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-button
            class="btn-sm btn-nav-link btn-nav-link-white lk-help mr-1"
            size="sm"
            variant="outline-primary"
            @click="loadData"
          >
            <feather-icon icon="SearchIcon" />
            Pesquisar
          </b-button>
          <b-button
            class="btn-sm btn-nav-link btn-nav-link-white lk-help"
            size="sm"
            variant="outline-primary"
            @click="report"
          >
            <feather-icon icon="FileTextIcon" />
            Exportar
          </b-button>
        </b-col>
      </b-row>
        <!-- <b-card-text><b>Cliente:</b> {{currentClient.nome_comercial}}</b-card-text>
        <b-card-text><b>Unidade:</b> {{currentUnit.nome_unidade}}</b-card-text>
   
        <div>
          <p>Selecione as datas</p>
          <date-picker 
            v-model="dateRange" 
            range
            type="date"
            format="DD/MM/YYYY"
            :disabled-date="disabledAfterToday"
            @change="loadData"
          ></date-picker>

          <b-button
            class=""
            size="sm"
            variant="flat-primary"
            @click="report"
          >
            <feather-icon icon="FileTextIcon" />
            Exportar
          </b-button>
        </div> -->

        <!-- variant="white" -->
        <b-overlay
          :show="tableLoading"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >

          <b-card>
            <!-- <template v-if="item.name">
              <h2>{{item.name}} ({{item.setorId}})</h2>
            </template> -->
            <custom-table
                :displayLoading="tableLoading"
                :fields="fields"
                :items="tableData"
                :hasEditButton="false"
                :hasDeleteButton="false"
                :totalRows="totalRows"
                :limit="tableData.length"
                :hasPages="false"
                :fixedHeader="true"
                @changePage="onChangePage"
                @onSortChange="onSortChange"
                @onColumnFilter="onFilter"
              />
                <!-- @onEdit="onEdit"
                @onDelete="onDelete" -->
            <hr>
          </b-card>
        </b-overlay>

        <!-- <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="test"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a3"
          pdf-orientation="landscape"
            
          @progress="onProgress($event)"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
          ref="html2Pdf"
      >
          <section slot="pdf-content">
          </section>
      </vue-html2pdf> -->
    </b-card>
  </div>
</template>

<script>
// import VueHtml2pdf from 'vue-html2pdf';
import CustomTable from '../../components/CustomTable.vue';
import AppConstants from '../../appConstants';
import reportService from '../../services/reportService';
import DatePicker from 'vue2-datepicker';
import { BCard, BCardText, BFormGroup, BRow } from 'bootstrap-vue';
import { msg } from '@/global'
import dayjs from 'dayjs';
import xlsx from "json-as-xlsx";
import vSelect from "vue-select";
import eventService from '@/services/eventService';

export default {
  components: { 
    CustomTable,
    BCard,
    BCardText,
    DatePicker,
    vSelect,
    BFormGroup,
    BRow,
    // VueHtml2pdf
  },
  data() {
    return {
      dateRange: [],
      sortField: {},
      tableData: [],
      totalRows: 0,
      limit: AppConstants.PAGINATOR_DEFAULT_LIMIT,
      page: 1,
      isSyncing: false,
      tableLoading: false,
      fields: [
        // { field: "action_buttons", label: "Ações", sortable: false },
        { field: "setor", label: "Setor", sortable: false, tdClass: this.tdClassTotalRows },
        { field: "cooperados", label: "Cooperados", sortable: false, tdClass: this.tdClassTotalRows },
        { field: "total_horas_trabalhadas", label: "Total Horas Trabalhadas", sortable: false, tdClass: this.tdClassTotalRows },
        { field: "carga_normal", label: "Carga Normal", sortable: false, tdClass: this.tdClassTotalRows },
        { field: "hora_extra", label: "Hora Extra", sortable: false, tdClass: this.tdClassTotalRows },
        { field: "hora_noturna", label: "Hora Noturna", sortable: false, tdClass: this.tdClassTotalRows },
        { field: "hora_extra_noturna", label: "Hora Extra Noturna", sortable: false, tdClass: this.tdClassTotalRows },
        { field: "valorStr", label: "Valor", sortable: false, tdClass: this.tdClassTotalRows },
        { field: "valorHoraExtraStr", label: "Hora Extra", sortable: false, tdClass: this.tdClassTotalRows },
        { field: "valorHoraNoturna", label: "Hora Noturna", sortable: false, tdClass: this.tdClassTotalRows },
        { field: "valorHoraExtraNoturna", label: "Hora Extra Noturna", sortable: false, tdClass: this.tdClassTotalRows },
        { field: "valorTotalStr", label: "Total", sortable: false, tdClass: this.tdClassTotalRows },
      ],
      currentClient: {},
      currentUnit: {},
      selectedSector: [],
      sectorList: [],
    };
  },
  created() {
    // const idCliente = localStorage.getItem('idCliente');
    const clientes = localStorage.getItem('clientesUnidades');
    const clientesUnidades = JSON.parse(clientes) || [];
    // console.log('[clientesUnidades]', clientesUnidades);
    const ids = this.getIdClienteUnidade;
    this.currentClient = clientesUnidades.find(item => item.id == ids.idCliente);
    this.currentUnit = this.currentClient.unidades.find(item => item.id == ids.idUnidade);

    this.loadDataFilter();
  },
  watch: {
    selectedSector(val) {
      if (val.length > 0)  {
        if (val.findIndex(item => item.id == null) >= 0) {
          const list = JSON.parse(JSON.stringify(this.sectorList));
          list.shift();
          this.selectedSector = list;
        }
      }
    }
  },
  computed: {
    getIdClienteUnidade() {
      return this.$store.state.escala.idClienteUnidade;
    },
    searchParams() {
      const params = new URLSearchParams();
      // params.append("page", this.page);
      // params.append("limit", this.limit);

      // TODO - UNCOMENT
      // const ids = this.getIdClienteUnidade
      params.append('scaleClosed', true);
      params.append('id_cliente', this.currentClient.id);
      params.append('id_unidade', this.currentUnit.id);
      if (this.selectedSector.length > 0 ) {
        params.append('sector_list', this.selectedSector.map(item => item.id).join());
      }
      
      // params.append('id_cliente', 7);
      // params.append('id_unidade', 24);
      const dataIni = dayjs(this.dateRange[0]).format('YYYY-MM-DD')
      const dataFim = dayjs(this.dateRange[1]).format('YYYY-MM-DD')
      params.append('data_ini', dataIni);
      params.append('data_fim', dataFim);
      // params.append('data_ini', '2023-09-18');
      // params.append('data_fim', '2023-09-22');
      // console.log('searchParams', this.dateRange);

      if (this.sortField.field) {
        params.append(`sort_${this.sortField.field}`, this.sortField.type);
      }
      return params;
    },
  },
  methods: {
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today
    },
    tdClassTotalRows(row) {
      if (row['totalRow']) return 'table-custom-total-row';
      return '';
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    loadDataFilter() {
      this.sectorList = [];
      this.selectedSector = [];
      const params = new URLSearchParams();
      params.append('id_cliente', this.currentClient.id);
      params.append('id_unidade', this.currentUnit.id);
      eventService.getSectors(params).then(res => {
        this.sectorList = res;
        this.sectorList.unshift({id: null, nome_setor: 'Todos', nome_unidade: ''})
      })
    },
    loadData() {
      if (!this.dateRange[0] || !this.dateRange[1]) {
        msg('Favor selecione uma data válido', 'vermelho', 5000)
        return;
      }
      this.tableData = [];
      this.tableLoading = true;
      reportService
        .getWeeklySummary(this.searchParams)
        .then((res) => {
          if (res && res.length > 0) {
            this.tableData = res;
          }
          // this.totalRows = res.totalRows;
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          if (err.response && err.response.data) {
            console.log(err.response.data);
            msg(err.response.data.error, 'vermelho', 5000);
          }
        });
    },
    onSortChange(params) {
      this.sortField = params;
      this.loadData();
    },
    onChangePage(page) {
      this.page = page;
      this.loadData();
    },
    onFilter() {
      // this.filter = data;
      // console.log('OnFilter: ', this.filter);
      this.page = 1;
      // setRouteQuery(data, this.$route.query);
      this.loadData();
    },
    report() {
      const dataIni = dayjs(this.dateRange[0]).format('DD_MM');
      const dataFim = dayjs(this.dateRange[1]).format('DD_MM');
      this.exportReportData(this.tableData, dataIni, dataFim);
    },
    exportReportData(listData, dataIni, dataFim) {
      const columns = [
        { label: "Setor", value: "setor" },
        { label: "Cooperados", value: "cooperados" },
        { label: "Total Horas Trabalhadas", value: "total_horas_trabalhadas" },
        { label: "Carga Normal", value: "carga_normal" },
        { label: "Hora Extra", value: "hora_extra" },
        { label: "Hora Noturna", value: "hora_noturna" },
        { label: "Hora Extra Noturna", value: "hora_extra_noturna" },
        { label: "Valor", value: "valorStr" },
        { label: "Hora Extra", value: "valorHoraExtraStr" },
        { label: "Hora Noturna", value: "valorHoraNoturna" },
        { label: "Hora Extra Noturna", value: "valorHoraExtraNoturna" },
        { label: "Total", value: "valorTotalStr" },
      ]
      let reportData = [
        {
          sheet: 'Resumo Financeiro',
          columns: columns,
          content: listData,
        }
      ];

        let settings = {
          fileName: `relatorio_resumo_financeiro_${dataIni}_a_${dataFim}`, // Name of the resulting spreadsheet
          extraLength: 3, // A bigger number means that columns will be wider
          writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
          writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
          RTL: false, // Display the columns from right-to-left (the default value is false)
        }

        xlsx(reportData, settings) // Will download the excel file
    }
  }
}
</script>

<style>

</style>