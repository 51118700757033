import store from "@/stores/global";
import AppConstants from "../appConstants";
import api from "./api";
// import router from "@/config/router";
import localStorageHelper from "@/helpers/local-storage-helper";

class ReportService {
  validateToken(params, body) {
    return new Promise((resolve, reject) => {
      api.post(AppConstants.API_URL_AUTH_VALIDATE_TOKEN, body, { params })
        .then(response => {
          resolve(response.data || response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  logout() {
    localStorageHelper.deleteCurrentUser();
    localStorage.setItem('path', '/neutro');
    
    store.commit('auth/toggleMenu', false);
    store.commit('auth/setUser', null);
    store.commit('setClientesUnidades', null);
    window.location = '/login';
  }
  getConfig(url) {
    return new Promise((resolve, reject) => {
      api.get(url)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  updateClienteUnidadeLocalData() {
    return new Promise((resolve, reject) => {
      store.commit("auth/setLoading", true);
      api.get("webcliente/clientes")
        .then((res) => {
            localStorage.setItem("clientesUnidades", JSON.stringify(res.data));
            store.commit("setClientesUnidades", res.data);
            // store.commit("auth/toggleMenu", true);
            store.commit("auth/setLoading", false);
            resolve(res.data || res);
        })
        .catch((error) => {
            // console.log("eerororor", error.data);
            store.commit("auth/setLoading", false);
            // this.textLogin = 'Acessar'  
            // this.validating = false
            // msg(error.response.data, 'vermelho', 3000)
            reject(error);
        });   
    })
  }
  
}

export default new ReportService();
